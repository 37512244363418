import { siteName, publicSiteUrl, twitterAccount } from '../constants'

function getSeo(seo) {
  // eslint-disable-next-line no-param-reassign
  seo = seo || {}
  const title = seo.title ? seo.title : `${siteName} — All your get back tos stored in one place`
  const description =
    seo.description || 'Backtoit is the fastest and easiest way to collect, organize, track, share and bookmark any link on the web.'
  const canonical = seo.canonical ? `${publicSiteUrl}${seo.canonical}` : publicSiteUrl

  const defaultSeo = {
    description,
    openGraph: {
      url: publicSiteUrl,
      title,
      description,
      type: 'website',
      images: [
        {
          url: `${publicSiteUrl}/og.jpg`,
          width: 1280,
          height: 720,
          alt: siteName,
        },
      ],
      site_name: siteName,
    },
    twitter: {
      handle: '@jonidelv',
      site: `@${twitterAccount}`,
      cardType: 'summary_large_image',
    },
  }

  return {
    ...defaultSeo,
    ...seo,
    title,
    description,
    canonical,
  }
}

export default getSeo
